import ApiService from '@/common/api/api.service';

const AnoLetivoService = {
  async listar(paginacao) {
    let { data } = await ApiService.get(`AnoLetivo`, { params: paginacao });
    return data;
  },
  async buscarPorId(id) {
    let { data } = await ApiService.get(`AnoLetivo/${id}`);
    return data;
  },
  async buscarPorIdComSegmentacao(id) {
    let { data } = await ApiService.get(`AnoLetivo/obter-lista-segmentacoes`, {
      params: { id },
    });
    return data;
  },
  async salvar(form) {
    let result = [];
    if (!form.id) result = await ApiService.post(`AnoLetivo`, form);
    else result = await ApiService.put(`AnoLetivo`, form);
    return result;
  },
  async adicionarTurma(form) {
    return await ApiService.post(`AnoLetivo/adicionar-turma`, form);
  },
  async removerTurma(form) {
    return await ApiService.delete(
      `AnoLetivo/remover-turma?AnoLetivoId=${form.anoLetivoId}&TurmaId=${form.turmaId}`
    );
  },
  async excluir(id) {
    return await ApiService.delete(`AnoLetivo/${id}`);
  },
  async encerrarPeriodo(id) {
    return await ApiService.put(`AnoLetivo/encerramento?anoLetivoId=${id}`);
  },

  // docente
  async adicionarDocente(form) {
    const { data } = await ApiService.post(`AnoLetivo/adicionar-docente`, form);
    return data;
  },

  async removerDocente(form) {
    const { data } = await ApiService.delete(
      `AnoLetivo/remover-docente?AnoLetivoId=${form.anoLetivoId}&anoLetivoDocenteId=${form.anoLetivoDocenteId}`
    );
    return data;
  },
};

export default AnoLetivoService;
