import ApiService from "@/common/api/api.service";

const EnumeradoresService = {
  async listar(enumerador) {
    let { data } = await ApiService.get(`enumeradores/${enumerador}`);
    data.data = data.data.map((linha) => ({
      ...linha,
      objeto: linha,
    }));
    return data;
  },
};

export default EnumeradoresService;
