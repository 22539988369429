import ApiService from '@/common/api/api.service';
const apiBasePath = 'Contrato';
const ContratoService = {
  async buscarContratoPorMatriculaId(matriculaId) {
    let { data } = await ApiService.get(
      `${apiBasePath}/matricula/${matriculaId}`
    );
    return data;
  },

  async listar(paginacao) {
    let { data } = await ApiService.get(`${apiBasePath}`, {
      params: paginacao,
    });
    return data;
  },

  async listarCursoTurmaPorMatricula(matriculaId) {
    let { data } = await ApiService.get(
      `${apiBasePath}/obter-contratos-turma-por-matricula?matriculaId=${matriculaId}`
    );
    return data;
  },

  async listarCursoTurmaVigentesPorMatricula(matriculaId) {
    let { data } = await ApiService.get(
      `${apiBasePath}/obter-contratos-turma-vigentes-por-matricula?matriculaId=${matriculaId}`
    );
    return data;
  },

  async buscarPorId(id) {
    let { data } = await ApiService.get(`${apiBasePath}/${id}`);
    return data;
  },

  async buscarPorMatriculaId(matriculaId, paginacao) {
    let { data } = await ApiService.get(
      `${apiBasePath}/matricula/${matriculaId}`,
      {
        params: paginacao,
      }
    );
    return data;
  },

  async buscarContratoVigentePorMatriculaId(matriculaId, paginacao) {
    let { data } = await ApiService.get(
      `${apiBasePath}/matricula/contrato-vigente/${matriculaId}`,
      {
        params: paginacao,
      }
    );
    return data;
  },

  async salvar(form) {
    let result = await ApiService.post(`${apiBasePath}`, form);
    return result;
  },

  async atualizar(form) {
    let result = await ApiService.put(`${apiBasePath}`, form);
    return result;
  },

  async baixarPDF(id) {
    let { data } = await ApiService.get(`${apiBasePath}/imprimir/${id}`);
    return data;
  },

  async excluir(id) {
    return await ApiService.delete(`${apiBasePath}/${id}`);
  },

  async buscarTodosContratosQuePossuemAditivo(paginacao) {
    let { data } = await ApiService.get(
      `${apiBasePath}/contratos-com-aditivo`,
      {
        params: paginacao,
      }
    );
    return data;
  },

  // REMATRICULA
  async listarContratosParaRematriculaPaginado(filtro, paginacao) {
    const { data } = await ApiService.get(
      `${apiBasePath}/obter-todos-para-rematricula`,
      {
        params: {
          ...filtro,
          ...paginacao,
        },
      }
    );
    return data;
  },

  async gerarRematricula(filtro) {
    const { data } = await ApiService.post(
      `${apiBasePath}/rematricula-lote`,
      filtro
    );
    return data;
  },

  async imprimirContratosRematricula(filtro) {
    const { data } = await ApiService.get(
      `${apiBasePath}/imprimir-contratos-rematricula`,
      {
        params: {
          ...filtro,
        },
      }
    );
    return data;
  },

  async imprimirFichaDeMatricula(filtro) {
    const { data } = await ApiService.get(
      `${apiBasePath}/imprimir-ficha-rematricula`,
      {
        params: {
          ...filtro,
        },
      }
    );
    return data;
  },

  async ativarContratos(filtro) {
    const { data } = await ApiService.post(
      `${apiBasePath}/ativar-rematricula-lote`,
      filtro
    );
    return data;
  },

  async ativarContratoPorId(id) {
    const { data } = await ApiService.put(
      `${apiBasePath}/ativar-contrato${id}`
    );
    return data;
  },

  // ADITIVOS DE CONTRATO
  async buscarAditivosPorContratoId(contratoId) {
    let { data } = await ApiService.get(
      `${apiBasePath}/aditivo-contratos-por-contrato?contratoId=${contratoId}`
    );
    return data;
  },

  async adicionarAditivoContratos(form) {
    let { data } = await ApiService.post(
      `${apiBasePath}/adicionar-aditivo-contratos/`,
      form
    );
    return data;
  },

  //#region MotivoCancelamento
  async salvarMotivoCancelamento(form) {
    let { data } = await ApiService.post(
      `Contrato/motivo-cancelamento-contrato`,
      form
    );
    return data;
  },

  async obterMotivoCancelamentoContratoPorIdContrato(contratoId) {
    let { data } = await ApiService.get(
      `Contrato/motivo-cancelamento-contrato-id?contratoId=${contratoId}`
    );
    return data;
  },
  //#endregion

  async imprimirRelatorioValoresContratos(form) {
    let { data } = await ApiService.get(
      `${apiBasePath}/imprimir-relatorio-valores-contrato`,
      {
        params: {
          ...form,
        },
      }
    );
    return data;
  },

  // RELATORIOS
  async gerarFichaSocioEconomica(filtro) {
    let { data } = await ApiService.get(
      `${apiBasePath}/imprimir-relatorio-ficha-socio-economica`,
      { params: { ...filtro } }
    );
    return data;
  },

  async gerarEtiquetasSocioeconomica(filtro) {
    let { data } = await ApiService.get(
      `${apiBasePath}/imprimir-relatorio-ficha-socio-economica-etiqueta`,
      { params: { ...filtro } }
    );
    return data;
  },
  async gerarRelatorioSituacaoContratoPDF(filtro) {
    let { data } = await ApiService.get(
      `${apiBasePath}/imprimir-relatorio-situacao-contrato`,
      { params: { ...filtro } }
    );
    return data;
  },
  async gerarRelatorioSituacaoContratoExcel(filtro) {
    let { data } = await ApiService.get(
      `${apiBasePath}/gerar-relatorio-excel-situacao-contrato`,
      { params: { ...filtro } }
    );
    return data;
  },
};

export default ContratoService;
