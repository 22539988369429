<template>
  <div>
    <titulo-relatorio-situacao-contrato />
    <b-row>
      <b-col cols="12" md="3" class="mt-3">
        <input-select
          ref="tipoRelatorio"
          v-model="filtro.tipoRelatorio"
          :label="$t('ACADEMICO.TIPO_RELATORIO')"
          :options="opcoes.tiposRelatorio"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select
          ref="anoLetivoId"
          v-model="filtro.anoLetivoId"
          :label="$t('ACADEMICO.PERIODO_LETIVO')"
          :options="opcoes.anosLetivos"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select
          ref="tipoContrado"
          v-model="filtro.tipoContrato"
          :label="$t('ACADEMICO.TIPO_CONTRATO')"
          :options="opcoes.tipoContratos"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select
          ref="statusRematricula"
          v-model="filtro.statusRematricula"
          :label="$t('ACADEMICO.SITUACAO_CONTRATO')"
          :options="opcoes.statusContratos"
          mostraTodos
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-date-only-btn
          ref="dataGeracaoInicio"
          v-model="filtro.dataGeracaoInicio"
          :label="$t('ACADEMICO.DATA_GERACAO_INICIO')"
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-date-only-btn
          ref="dataGeracaoAte"
          v-model="filtro.dataGeracaoAte"
          :label="$t('ACADEMICO.DATA_GERACAO_FINAL')"
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-date-only-btn
          ref="dataInativacaoInicio"
          v-model="filtro.dataInativacaoInicio"
          :label="$t('ACADEMICO.DATA_INATIVACAO_INICIO')"
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-date-only-btn
          ref="dataInativacaoAte"
          v-model="filtro.dataInativacaoAte"
          :label="$t('ACADEMICO.DATA_INATIVACAO_FINAL')"
          :required="false"
        />
      </b-col>

      <b-col cols="12" md="auto" class="mt-md-5">
        <input-checkbox
          v-model="filtro.separarPorFolha"
          type="boolean"
          :label="$t('ACADEMICO.SEPARAR_TURMAS_FOLHAS')"
          :placeholder="$t('ACADEMICO.SEPARAR_TURMAS_FOLHAS')"
        />
      </b-col>
      <b-col cols="12" md="auto" class="mt-3 mb-2 mb-md-0">
        <b-button
          variant="secondary"
          class="w-100 botao-acao-filtro"
          @click="limparFiltro"
        >
          {{ $t('GERAL.LIMPAR') }}
        </b-button>
      </b-col>
      <b-col cols="12" md="auto" class="mt-3 mb-2 mb-md-0">
        <b-button
          variant="info"
          class="w-100 botao-acao-filtro"
          @click="gerarRelatorioPdf"
        >
          {{ $t('GERAL.GERAR_RELATORIO') }}
        </b-button>
      </b-col>
      <b-col cols="12" md="auto" class="mt-3 mb-2 mb-md-0">
        <b-button
          variant="info"
          class="w-100 botao-acao-filtro"
          @click="baixarRelatorioFormatoExcel"
        >
          {{ $t('ACADEMICO.EXPORTAR_PARA_EXCEL') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import ContratoService from '@/common/services/contratos/contratos.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

// Components:
import {
  InputDateOnlyBtn,
  InputSelect,
  InputCheckbox,
} from '@/components/inputs';
import TituloRelatorioSituacaoContrato from '@/views/academico/relatorios/relatorio-situacao-contrato/TituloRelatorioSituacaoContrato';

export default {
  components: {
    TituloRelatorioSituacaoContrato,
    InputDateOnlyBtn,
    InputSelect,
    InputCheckbox,
  },
  template: {},
  data() {
    return {
      filtro: {
        separarPorFolha: false,
        statusRematricula: null,
      },
      opcoes: {
        tiposRelatorio: [],
        anosLetivos: [],
        tipoContratos: [],
        statusContratos: [],
      },
    };
  },
  mounted() {
    this.getAnosLetivos();
    this.getTipoContratos();
    this.getSituacaoContratoEnum();
    this.getTipoRelatorio();
  },
  methods: {
    // RELATORIOS
    gerarRelatorioPdf() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      ContratoService.gerarRelatorioSituacaoContratoPDF(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    baixarRelatorioFormatoExcel() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      ContratoService.gerarRelatorioSituacaoContratoExcel(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // OBTER DADOS
    getTipoRelatorio() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-relatorio')
        .then(({ data }) => {
          data = data.map((row) => ({
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tiposRelatorio = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getSituacaoContratoEnum() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-rematricula')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.statusContratos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getTipoContratos() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-contratos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipoContratos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              text: row.ano,
              value: row.id,
            };
          });
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES FILTRO
    limparFiltro() {
      this.filtro = {
        separarPorFolha: false,
      };
    },

    validarFormulario() {
      if (this.filtro.periodoFim < this.filtro.periodoInicial) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('ACADEMICO.DATAS_INVALIDAS')
        );
        return;
      }
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>

<style>
.ajuste-fino-btn {
  margin-top: 29px;
}
</style>
